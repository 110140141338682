.projects {
  font-family: "the-seasons", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  line-height: 170.39px;
  display: flex;
  flex-direction: column;
  margin: 0 250px 200px;
}

.projects a:hover {
  transform: none;
}

.projects .job {
  display: flex;
  align-items: center;
}

.projects .job.align-right {
  align-self: end;
  flex-direction: row-reverse;
  text-align: right;
  height: 150px;
  display: flex;
  // flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.projects .job.align-left {
  align-self: start;
  text-align: left;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.job:hover {
  .masked-content {
    display: block;
    order: 2;
  }
  .masked-content-right {
    display: block;
    order: 2;
  }
  .bar-none-2 {
    display: none !important;
  }
}

.projects .job.is-hidden {
  display: none;
}

.bar-none {
  display: none;
}

.bar-none-2 {
  order: 2;
}

.home {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  @keyframes bganimation {
    0% {
      background-position: -100% -100%, 200% 200%, -100% 200%, 200% -100%;
    }
    50% {
      background-position: 150% 100%, -200% 100%, 100% 0%, 0% 100%;
    }
    100% {
      background-position: -100% -100%, 200% 200%, -100% 200%, 200% -100%;
    }
  }
  background: radial-gradient(
      ellipse at center,
      #c7377854,
      rgba(0, 0, 0, 0) 70%
    ),
    /* niebieski */
      radial-gradient(ellipse at center, #ffc7114a, rgba(0, 0, 0, 0) 70%),
    /*czerwony */
      radial-gradient(ellipse at center, #8de7324e, rgba(0, 0, 0, 0) 70%)
      /*zielony */ #3d3b8e;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 1000px 1000px, 1000px 1000px, 1000px 1000px;
  background-attachment: fixed;
  animation: bganimation 30s infinite;

  .selectors {
    display: flex;
    flex-direction: row;
    padding: 100px 0 0 0;
    flex-wrap: wrap;
  }

  .selectors a {
    padding-right: 30px;
    font-size: 20px;
    text-align: center;
  }

  .selectors .custom-hover.underline {
    color: #e67373;
  }

  .hide-content {
    font-size: 30px;
    font-family: lato, sans-serif;
    font-style: normal;
    font-weight: 800;
    display: none;
  }

  .hide-content span {
    font-style: italic;
    font-weight: 100;
  }

  .filter {
    font-style: normal;
    font-weight: 800;
    display: block;
    height: 28px;
  }

  .masked-content {
    font-family: lato, sans-serif;
    display: none;
    white-space: nowrap; /* Empêche le texte de se diviser sur plusieurs lignes */
    font-size: 20px;
    margin: 0 20px;
    position: relative;
    top: 12px;
  }
  .masked-content-right {
    font-family: lato, sans-serif;
    display: none;
    white-space: nowrap; /* Empêche le texte de se diviser sur plusieurs lignes */
    font-size: 20px;
    margin: 0 20px;
    position: relative;
    top: 12px;
  }
}
