// extra-ecran à big-desktop
@media only screen and (max-width: 1399px) {
  .flower-container {
    position: fixed;
    bottom: 40px;
    right: 50px;
    width: 90px;
  }
  .flex-1 h1,
  .flex-2 h1 {
    font-size: 220px;
    line-height: 220px;
  }

  .flex-1 div,
  .flex-2 div {
    max-width: 190px;
    height: 0px;
    border: solid 2px #fff;
  }
  .home {
    .align-left span {
      display: none;
    }

    .center {
      font-size: 90px;
    }

    header {
      margin-bottom: 150px;
    }

    .projects {
      margin: 0 200px 200px;
    }

    .selectors a {
      font-size: 18px;
    }
  }

  .web-id,
  .house,
  .poster,
  .figurine,
  .vases,
  .cover,
  .abstract,
  .magazine,
  .nymphe,
  .music,
  .bota,
  .game {
    .flex-1 h1,
    .flex-2 h1 {
      font-size: 180px;
      line-height: 180px;
    }

    .description,
    .infos {
      font-size: 15px;
    }

    .description {
      width: 500px;
    }

    .top-picture {
      max-width: 800px;
      max-height: 700px;
      position: absolute;
      top: 200px;
    }

    header {
      margin-bottom: 100px;
    }

    .big-layout {
      margin-bottom: 50px;
    }

    .layout {
      margin-bottom: 30px;
    }

    h2 {
      margin: 50px 0;
    }

    .other-projects {
      margin-bottom: 80px;
      font-size: 18px;
    }

    .footer {
      margin: 30px 0;
    }
  }
}

// big-desktop à sm-desktop
@media only screen and (max-width: 1199px) {
  .flower-container {
    position: fixed;
    bottom: 40px;
    right: 50px;
    width: 65px;
  }
  .flex-1 h1,
  .flex-2 h1 {
    font-size: 200px;
    line-height: 200px;
  }

  .flex-1 div,
  .flex-2 div {
    max-width: 160px;
    height: 0px;
    border: solid 2px #fff;
  }
  .home {
    .center {
      font-size: 80px;
    }

    header {
      margin-bottom: 100px;
    }

    .projects {
      margin: 0 150px 200px;
    }
  }

  .web-id,
  .house,
  .poster,
  .figurine,
  .vases,
  .cover,
  .abstract,
  .magazine,
  .nymphe,
  .music,
  .bota,
  .game {
    .flex-1 h1,
    .flex-2 h1 {
      font-size: 120px;
      line-height: 120px;
    }

    .description,
    .infos {
      font-size: 15px;
    }

    .description {
      width: 500px;
    }

    .top-picture {
      max-width: 700px;
      max-height: 600px;
      position: absolute;
      top: 100px;
    }

    header {
      margin-bottom: 100px;
    }

    .big-layout {
      margin-bottom: 80px;
    }

    .layout {
      margin-bottom: 80px;
    }

    h2 {
      margin: 0 0 50px;
    }

    .other-projects {
      margin-bottom: 50px;
      font-size: 15px;
    }

    .footer {
      margin-bottom: 30px;
    }
  }
}

// sm-desktop à tablet
@media only screen and (max-width: 991px) {
  .flower-container {
    display: none;
  }
  .flex-1 h1,
  .flex-2 h1 {
    font-size: 180px;
    line-height: 180px;
  }

  .home {
    .center {
      font-size: 70px;
    }

    header {
      margin-bottom: 100px;
    }

    .projects {
      margin: 0 100px 200px;
    }
  }
  .web-id,
  .house,
  .poster,
  .figurine,
  .vases,
  .cover,
  .abstract,
  .magazine,
  .nymphe,
  .music,
  .bota,
  .game {
    .flex-1 h1,
    .flex-2 h1 {
      font-size: 85px;
      line-height: 85px;
    }

    .description,
    .infos {
      font-size: 15px;
    }

    .description {
      width: 500px;
    }

    .top-picture {
      max-width: 600px;
      max-height: 500px;
      position: absolute;
      top: 100px;
    }

    header {
      margin-bottom: 100px;
    }

    .big-layout {
      margin-bottom: 80px;
    }

    .layout {
      margin-bottom: 80px;
    }

    h2 {
      margin: 0 0 50px;
    }

    .other-projects {
      margin-bottom: 50px;
      font-size: 15px;
    }

    .footer {
      margin-bottom: 30px;
    }
  }

  .figurine {
    .top-picture {
      max-width: 500px;
      max-height: 400px;
      position: absolute;
      top: 120px;
    }
  }
}

// tablet à mobile
@media only screen and (max-width: 767px) {
  .flex-1 h1,
  .flex-2 h1 {
    font-size: 160px;
    line-height: 160px;
  }

  nav {
    margin-bottom: 100px;
    margin-top: 30px;
  }

  nav a {
    font-size: 12px;
  }

  header {
    margin-bottom: 80px;
  }
  .home {
    .center {
      font-size: 60px;
    }

    .selectors {
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    .selectors a {
      padding-top: 10px;
    }
  }
  .web-id,
  .house,
  .poster,
  .figurine,
  .vases,
  .cover,
  .abstract,
  .magazine,
  .nymphe,
  .music,
  .bota,
  .game {
    .flex-1 h1,
    .flex-2 h1 {
      font-size: 65px;
      line-height: 65px;
    }

    .flex-1 div,
    .flex-2 div {
      max-width: 100px;
      height: 0px;
      border: solid 2px #fff;
    }

    .description,
    .infos {
      font-size: 15px;
      // max-width: 300px;
      text-align: center;
      margin-top: 80px;
    }

    .description {
      margin-top: 140px;
    }

    .top-picture {
      max-width: 400px;
      max-height: 300px;
      position: absolute;
      top: 100px;
    }

    .big-layout {
      margin-bottom: 50px;
    }

    .layout {
      margin-bottom: 50px;
    }

    h2 {
      margin: 50px 0;
      font-size: 18px;
    }

    .other-projects {
      margin-bottom: 30px;
      font-size: 15px;
    }

    .footer {
      margin-bottom: 30px;
    }
  }

  .abstract {
    .first-video {
      margin-bottom: 50px;
    }
  }

  .web-id {
    .picture-2 {
      display: none;
    }
  }

  .poster,
  .vases,
  .cover,
  .magazine,
  .house,
  .nymphe,
  .music,
  .bota,
  .game {
    .picture-1 {
      margin-bottom: 50px;
    }
  }

  .figurine {
    .picture-1,
    .picture-2 {
      margin-bottom: 50px;
    }
  }
}
