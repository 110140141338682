.about {
  background-color: #3d3b8e;

  .text {
    width: 700px;
    padding-bottom: 70px;
  }

  .title {
    color: #fbfef9;
    font-size: 180px;
    line-height: 180px;
  }

  .text-right {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: #fbfef9;
  }

  .links {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 700px;
  }

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 50px 0 200px;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: #fbfef9;
    padding: 50px 0 100px;
    margin: 0;
  }

  nav a {
    font-size: 15px;
    color: #fbfef9;
  }

  a {
    text-decoration: none;
    color: #e67373;
  }

  a:hover {
    transform: scale(1.1);
  }

  .span {
    color: #e67373;
  }
}
