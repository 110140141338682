// .band-container {
//   height: 875px;
//   width: 1560px;
//   overflow: hidden;
//   position: relative;
// }

.band-container {
  width: 100%;
  height: 70vh;
  overflow-x: auto;
  white-space: nowrap;
}

.band-container img {
  height: 100%;
  display: inline-block;
  width: auto;
  max-width: none;
}
