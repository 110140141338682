/* ====================================
// css variables
// ==================================== */

:root {
  --home: #4846a8;
  --house: #478d77;
  --eikonica: #5184be;
  --solarsystem: #38476c;
  --doraemon: #417ca0;
  --vase: #294831;
  --cover: #894141;
  --abstract: #4c62a5;
  --magazine: #377c82;
  --bota: #1c1941;
  --nymphe: #125b4b;
  --music: #183f30;
  --game: #242e21;
}

:root {
  --cover-size: 500px; /* Adjust the value accordingly */
}

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  line-height: 1.25;
}

a {
  color: inherit;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

/* ====================================
// base rules

font-family: the-seasons, sans-serif;
font-style: normal;
font-weight: 300;

font-family: lato, sans-serif;
font-style: normal;
font-weight: 100;
// ==================================== */

html {
  font-family: "lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: white;
  font-size: 20px;
}

body {
  min-width: 30px;
}

.custom-cursor:hover {
  transform: scale(1.1);
}

header {
  margin-bottom: 200px;
}

.top-picture {
  position: absolute;
  right: 0;
  top: 200px;
  height: 800px;
  z-index: -1;
}

h1 {
  font-family: "the-seasons", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 250px;
  letter-spacing: 5.38px;
  line-height: 245px;
}

.title {
  font-size: 180px;
  line-height: 180px;
}

.description {
  margin-top: 130px;
}

.infos {
  margin-top: 100px;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 50px 0 200px;
  text-transform: uppercase;
  letter-spacing: 4px;
  position: relative;
  font-size: 15px;
}

.social-media {
  text-transform: uppercase;
  letter-spacing: 4px;
  position: relative;
}

a {
  text-decoration: none;
}

a:hover {
  transform: scale(1.1);
}

.insta {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.social-media:hover .name {
  opacity: 0;
}

.social-media:hover .insta {
  opacity: 1;
  pointer-events: auto;
}

li {
  list-style: none;
}

.flex-1,
.flex-2 {
  display: flex;
  flex-direction: row;
  gap: 6%;
}

.flower-container {
  position: fixed;
  bottom: 40px;
  right: 50px;
}

.flower {
  display: flex;
  flex-direction: row-reverse;
}

.layout {
  margin-bottom: 30px;
}

.big-layout {
  margin-bottom: 200px;
}

img {
  max-width: 100%;
}

.house {
  background-color: var(--house);
}

.web-id {
  background-color: var(--eikonica);
}

.poster {
  background-color: var(--solarsystem);
}

.figurine {
  background-color: var(--doraemon);
}

.vases {
  background-color: var(--vase);
}

.cover {
  background-color: var(--cover);
}

.abstract {
  background-color: var(--abstract);
}

.magazine {
  background-color: var(--magazine);
}

.bota {
  background-color: var(--bota);
}

.nymphe {
  background-color: var(--nymphe);
}

.music {
  background-color: var(--music);
}

.game {
  background-color: var(--game);
}

h2 {
  margin: 100px 0 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 4px;
}

.other-projects {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;
  font-family: "the-seasons", sans-serif;
  margin-bottom: 100px;
}

.home-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 3;
}

.center {
  font-size: 100px;
}

.flex-1 .bar,
.flex-2 .bar {
  width: 260px;
  height: 0px;
  border: solid 2px #fff;
}

.flex-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  animation-name: example;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  opacity: 0%;
}

.flex-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  opacity: 0%;
  animation-delay: 0.3s;
}

@keyframes fadeIn {
  0% {
    transform: translate(-200px, 0);
    top: 0px;
    opacity: 0%;
  }
  100% {
    transform: translate(0, 0);
    top: 0px;
    opacity: 100%;
  }
}

.flex-1,
.flex-2 {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}

.first-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // height: 110%;
}
